import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Heading from '@ratedpeople/heading';
import LottieLoader from '@ratedpeople/lottie-loader';
import classNames from 'classnames';

import style from './style.module.css';

export default function BlogPosts() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getBlogPosts() {
      setIsLoading(true);

      try {
        const [{ data: postsResponse }, { data: trendsHubResponse }] = await Promise.all([
          axios.get(`https://www.ratedpeople.com/blog/wp-json/wp/v2/posts?per_page=5&tags=3313&_embed`, {
            withCredentials: true
          }),

          axios.get(`https://www.ratedpeople.com/blog/wp-json/wp/v2/pages?slug=home-improvement-trends-report&_embed`, {
            withCredentials: true
          })
        ]);

        const tilesData = trendsHubResponse.concat(postsResponse);

        /**
         * If data has the HO Trends Hub (type WordPress Page) then
         * order the data so that HO Trends Hub is always at
         * large tile.
         */
        if (tilesData.length > 5) {
          const trendsHub = tilesData.shift();
          tilesData.splice(1, 0, trendsHub);
          tilesData.pop();
        }

        setPosts(tilesData);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    }

    getBlogPosts();
  }, []);

  return (
    <LottieLoader isLoading={isLoading}>
      <section className={style.container}>
        {Boolean(posts.length) &&
          posts.map((post) => {
            const media = post._embedded['wp:featuredmedia']?.find(
              (media) => media.media_type === 'image' && media.type === 'attachment'
            );

            const categories =
              post._embedded['wp:term']
                ?.map((taxonomies) => {
                  return taxonomies.filter((terms) => {
                    return terms.taxonomy === 'category';
                  });
                })
                .filter((c) => Boolean(c.length)) ?? [];

            const category =
              Boolean(categories.length) &&
              categories[0].find((cat) => cat.slug === 'inspiration' || cat.slug === 'advice' || cat.slug === 'trade');

            return (
              <a
                className={style.media}
                loading="lazy"
                style={{ backgroundImage: media?.source_url ? `url(${media?.source_url})` : 'none' }}
                key={post.id}
                href={post.link}
              >
                {category && (
                  <div className={classNames(style.category, style[category.slug])}>
                    {category.slug === 'advice'
                      ? category.slug === 'trade'
                        ? 'Trade advice'
                        : 'Homeowner advice'
                      : category.name}
                  </div>
                )}
                <Heading size={2} weight="bold" className={style.heading}>
                  {post.title.rendered}
                </Heading>
              </a>
            );
          })}
      </section>
    </LottieLoader>
  );
}
