import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import get from 'lodash-es/get';
import head from 'lodash-es/head';
import qs from 'qs';

import Grid from '@ratedpeople/grid';
import Row from '@ratedpeople/row';
import Col from '@ratedpeople/col';
import PopularSkills from '@ratedpeople/popular-skills';
import Heading from '@ratedpeople/heading';
import JobFormEntry from '@ratedpeople/job-form-entry';
import VideoSlider from '@ratedpeople/video-slider';
import MobileAppBanner from '@ratedpeople/mobile-app-banner';
import SMSLink from '@ratedpeople/sms-link';

import Hero from '../Hero';
import SimpleCard from '../SimpleCard';
import MetaData from '../MetaData';
import BlogPosts from '../BlogPosts';
import tradesAndServicesList from './assets/data/tradesAndServicesList';
import featureCardList from './assets/data/featureCardList';

import shieldImage from './assets/images/Shield.svg';
import heroImageSmall from './assets/images/job-form-entry/hero-image-small.jpg';
import heroImageSmallWebP from './assets/images/job-form-entry/hero-image-small.webp';
import heroImageMedium from './assets/images/job-form-entry/hero-image-medium.jpg';
import heroImageMediumWebP from './assets/images/job-form-entry/hero-image-medium.webp';
import heroImageLarge from './assets/images/job-form-entry/hero-image-large.jpg';
import heroImageLargeWebP from './assets/images/job-form-entry/hero-image-large.webp';
import firstTimeBuyer from './assets/images/video-slider/firstTimeBuyer.jpg';
import businessOwner from './assets/images/video-slider/businessOwner.jpg';
import projectManager from './assets/images/video-slider/projectManager.jpg';

import style from './style.module.scss';

const propTypes = {
  data: PropTypes.object
};

const metaData = {
  title: 'Find a tradesperson | Recommended trusted local trader job quotes | Rated People',
  metadesc:
    'Get access to free quotes from over 50,000 UK tradespeople, including builders, electricians, roofers, plumbers & more.',
  opengraph_image: '//a.rpassets.com/seo/logos/rated-people-logo.png'
};

let backgroundImages = [
  {
    sizeImage: 'small',
    source: heroImageSmallWebP,
    sourceFallback: heroImageSmall
  },
  {
    sizeImage: 'medium',
    source: heroImageMediumWebP,
    sourceFallback: heroImageMedium
  },
  {
    sizeImage: 'large',
    source: heroImageLargeWebP,
    sourceFallback: heroImageLarge
  }
];

const schema = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Rated People',
  description:
    "Rated People is the UK's number 1 online marketplace connecting homeowners with quality local tradespeople.",
  url: 'https://www.ratedpeople.com',
  logo: {
    '@type': 'ImageObject',
    url: 'https://a.rpassets.com/seo/logos/rated-people-logo.png',
    height: '713',
    width: '1200'
  },
  image: {
    '@type': 'ImageObject',
    url: 'https://a.rpassets.com/seo/logos/rated-people-logo.png',
    height: '713',
    width: '1200'
  },
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+44 345 117 8000',
      contactType: 'Customer Service',
      areaServed: 'UK'
    }
  ],
  email: 'sales@ratedpeople.com',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'United Kingdom',
    addressLocality: 'London',
    postalCode: 'SE1 8NW',
    streetAddress: '240 Blackfriars Road'
  },
  sameAs: [
    'https://www.facebook.com/ratedpeople',
    'https://twitter.com/ratedpeople',
    'https://www.youtube.com/user/RatedPeople',
    'https://www.linkedin.com/company/rated-people'
  ]
};

const LandingPage = (props) => {
  const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const showTypeahead = get(queryParams, 'variation') === 'att-212';

  return (
    <Fragment>
      <MetaData
        {...metaData}
        opengraph_title={metaData.title}
        opengraph_description={metaData.metadesc}
        schema={schema}
      />
      <div className={`${style[`hero-section`]}`}>
        {showTypeahead ? (
          <Hero
            ident="full-width-themed"
            inlineStyleJobFormArea={{
              maxWidth: '900px'
            }}
            theme="hero"
            backgroundImages={backgroundImages}
            contentTexts={{
              texts: [
                {
                  size: 'small',
                  title: 'Find a local tradesperson for your job.',
                  description: 'Post your job for free. Match to tradespeople, get quotes and read reviews.'
                },
                {
                  size: 'medium',
                  title: 'Find a local tradesperson for your job.',
                  description: 'Post your job for free. Match to tradespeople, get quotes and read reviews.'
                },
                {
                  size: 'large',
                  title: 'Find a local tradesperson for your job.',
                  description: 'Post your job for free. Match to tradespeople, get quotes and read reviews.'
                }
              ]
            }}
            flexDirection="row"
          />
        ) : (
          <JobFormEntry
            restApiHostname={process.env.GATSBY_REST_API_HOSTNAME}
            ident="full-width-themed"
            basePath="/post-a-job"
            inlineStyleJobFormArea={{
              maxWidth: '900px'
            }}
            theme="hero"
            backgroundImages={backgroundImages}
            contentTexts={{
              texts: [
                {
                  size: 'small',
                  title: 'Find a local tradesperson for your job.',
                  description: 'Post your job for free. Match to tradespeople, get quotes and read reviews.'
                },
                {
                  size: 'medium',
                  title: 'Find a local tradesperson for your job.',
                  description: 'Post your job for free. Match to tradespeople, get quotes and read reviews.'
                },
                {
                  size: 'large',
                  title: 'Find a local tradesperson for your job.',
                  description: 'Post your job for free. Match to tradespeople, get quotes and read reviews.'
                }
              ]
            }}
            flexDirection="row"
            buttonText="Next step >"
          />
        )}
      </div>
      <div className={`${style.landing} ${style['landing-section']}`}>
        <div className={`${style['popular-skills-container']}`}>
          <Heading className={style['popular-skills_heading']} weight="bold" isUnderlined isCentered>
            Our most popular jobs
          </Heading>
          <PopularSkills />
        </div>
        <div className="container-fluid">
          <Heading isUnderlined isCentered weight="bold">
            How our service works
          </Heading>
          <Grid className={style['service-works-grid']}>
            <Row yAlignment="center" xAlignment="center">
              <Col className={style['service-works-collumn']} sm={12} md={4} lg={4} xl={4}>
                <p className={style['service-works-number']}>1</p>
                <div className={style['service-works-content']}>
                  <h3 className={style['service-works-title']}>Create a job for free</h3>
                  <p>Tell us what you need</p>
                </div>
              </Col>
              <Col className={style['service-works-collumn']} sm={12} md={4} lg={4} xl={4}>
                <p className={style['service-works-number']}>2</p>
                <div className={style['service-works-content']}>
                  <h3 className={style['service-works-title']}>Get a quote</h3>
                  <p>Tradespeople get in touch</p>
                </div>
              </Col>
              <Col className={style['service-works-collumn']} sm={12} md={4} lg={4} xl={4}>
                <p className={style['service-works-number']}>3</p>
                <div className={style['service-works-content']}>
                  <h3 className={style['service-works-title']}>Rate and review</h3>
                  <p>Job done - leave feedback</p>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
        <div className={style['app-section']}>
          <MobileAppBanner>
            <SMSLink
              branchKey={process.env.GATSBY_BRANCH_IO_KEY}
              campaign="web_rp_home_page"
              channel="RatedPeople-Web"
              feature="Homepage"
            />
          </MobileAppBanner>
        </div>
        <div className={`container-fluid ${style['homeowner-information-container']}`}>
          <Heading isUnderlined isCentered weight="bold">
            Hire safely with our homeowner checklist
          </Heading>
          <div className={style['homeowner-information']}>
            <Grid>
              <Row yAlignment="center">
                <Col sm={2} md={2} lg={2} xl={2}>
                  <LazyLoad offset={100}>
                    <img className={style['homeowner-information-image']} src={shieldImage} alt="Shield" />
                  </LazyLoad>
                </Col>
                <Col sm={10} md={10} lg={10} xl={10}>
                  <p>
                    Our essential tips for hiring a tradesperson. Never pay too much up front, and find out how to check
                    references, qualifications and insurance.
                  </p>
                  <div>
                    <a className={style['homeowner-information-link']} href="/c/homeowner-checklist">
                      {'Read our homeowner checklist >'}
                    </a>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>

        <Grid>
          <BlogPosts />
        </Grid>

        {/*

        @note keeping this here until this is settled just in case
        @todo once marketing is happy with homepage content
              then remove this and the relevant CSS

        <Grid className={style['simple-cards']}>
          <Heading isUnderlined isCentered weight="bold">
            Inspiration and Planning
          </Heading>
          <Row>
            <Col sm={12} className={style['simple-cards_padding']}>
              <SimpleCard detail={head(featureCardList)} />
            </Col>
          </Row>
          <Row>
            {featureCardList.slice(1, 4).map((featureCard, index) => {
              return (
                <Col key={featureCard.title} sm={12} md={4} className={style['simple-cards_padding']}>
                  <SimpleCard detail={featureCard} />
                </Col>
              );
            })}
          </Row>
        </Grid> */}

        <div className={style['video-slider']}>
          <LazyLoad offset={100}>
            <VideoSlider
              inlineStyles={{
                maxWidth: '1144px',
                padding: '0 10px 60px 10px'
              }}
              carouselItems={[
                {
                  videoUrl: 'https://www.youtube.com/embed/bDjt0sjayPY',
                  imageUrl: projectManager,
                  quote: 'As an employee, I also use Rated People because I trust the service.”',
                  title: 'Ruth, major roofing project',
                  description: '',
                  link: {
                    linkDisplayName: 'Find out more',
                    href: 'https://www.ratedpeople.com/c/how-it-works/homeowner-testimonials'
                  }
                },
                {
                  videoUrl: 'https://www.youtube.com/embed/naukggC5qjk',
                  imageUrl: firstTimeBuyer,
                  quote: 'As a first time buyer, Rated People has been invaluable.”',
                  title: 'Nick, loft conversion',
                  description: '',
                  link: {
                    linkDisplayName: 'Find out more',
                    href: 'https://www.ratedpeople.com/c/how-it-works/homeowner-testimonials'
                  }
                },
                {
                  videoUrl: 'https://www.youtube.com/embed/8D0Ayi71YO8',
                  imageUrl: businessOwner,
                  quote: 'As a business owner, it’s so important that we find tradespeople we can trust.”',
                  title: 'Kristie, emergency plumbing work',
                  description: '',
                  link: {
                    linkDisplayName: 'Find out more',
                    href: 'https://www.ratedpeople.com/c/how-it-works/homeowner-testimonials'
                  }
                }
              ]}
            />
          </LazyLoad>
        </div>
        <div className="container-fluid">
          <Heading isUnderlined isCentered weight="bold">
            Our Trades and Services
          </Heading>
          <Grid className={style['trades-services-list']}>
            <Row yAlignment="center">
              {tradesAndServicesList.map((item) => (
                <Col key={item.display} className={style['trades-services-list-item']} sm={12} md={6} lg={4} xl={4}>
                  <a className={style['trades-services-list-item-link']} href={item.href}>
                    {item.display}
                  </a>
                </Col>
              ))}
            </Row>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};

LandingPage.prototype = propTypes;

export default LandingPage;
