export const popularSkillResults = [
  {
    label: 'Internal Painting & Decorating',
    value: 'Internal Painting & Decorating',
    category: 'Popular jobs',
    tradeCode: 'decorator',
    jobTypeId: 20
  },
  {
    label: 'Electrical Installation / Testing',
    value: 'Electrical Installation / Testing',
    category: 'Popular jobs',
    tradeCode: 'electrician',
    jobTypeId: 27
  },
  {
    label: 'Plumbing Repair & Maintenance',
    value: 'Plumbing Repair & Maintenance',
    category: 'Popular jobs',
    tradeCode: 'plumber',
    jobTypeId: 58
  },
  {
    label: 'Bathroom, Kitchen and WC Plumbing',
    value: 'Bathroom, Kitchen and WC Plumbing',
    category: 'Popular jobs',
    tradeCode: 'plumber',
    jobTypeId: 56
  },
  {
    label: 'Gas Boiler - Installation',
    value: 'Gas Boiler - Installation',
    category: 'Popular jobs',
    tradeCode: 'heating',
    jobTypeId: 140
  },
  {
    label: 'Plasterer / Plaster Skimming',
    value: 'Plasterer / Plaster Skimming',
    category: 'Popular jobs',
    tradeCode: 'plasterer',
    jobTypeId: 50
  }
];
