export default [
  {
    display: 'Bathroom Fitter',
    href: '/local-bathroom-fitter'
  },
  {
    display: 'Blacksmith / Metal Worker',
    href: '/local-blacksmith-metal-worker'
  },
  {
    display: 'Bricklayer',
    href: '/local-bricklayers'
  },
  {
    display: 'Builder',
    href: '/local-builders'
  },
  {
    display: 'Carpenter / Joiner',
    href: '/local-carpenters-joiners'
  },
  {
    display: 'CCTV / Satellites / Alarms',
    href: '/local-cctv-satellites-alarms'
  },
  {
    display: 'Cleaner',
    href: '/local-cleaners'
  },
  {
    display: 'Drainage Specialist',
    href: '/local-drainage-specialists'
  },
  {
    display: 'Driverway Specialist',
    href: '/local-driveway-pavers'
  },
  {
    display: 'Electrician',
    href: '/local-electricians'
  },
  {
    display: 'Floor Fitters',
    href: '/local-floor-fitters'
  },
  {
    display: 'Gardener / Landscape Gardeners',
    href: '/local-gardener-landscape-gardeners'
  },
  {
    display: 'Gas / Heating Engineer',
    href: '/local-gas-heating-engineer'
  },
  {
    display: 'Handyperson',
    href: '/local-handyperson'
  },
  {
    display: 'Kitchen Specialist',
    href: '/local-kitchen-specialists'
  },
  {
    display: 'Locksmith',
    href: '/local-locksmiths'
  },
  {
    display: 'Loft Conversion Specialist',
    href: '/local-loft-conversion-specialists'
  },
  {
    display: 'Painter and decorator',
    href: '/local-painter-and-decorator'
  },
  {
    display: 'Pest Control',
    href: '/local-pest-control'
  },
  {
    display: 'Plasterer / Renderer',
    href: '/local-plasterers-renderers'
  },
  {
    display: 'Plumber',
    href: '/local-plumbers'
  },
  {
    display: 'Removal Services',
    href: '/local-removals'
  },
  {
    display: 'Roofer',
    href: '/local-roofers'
  },
  {
    display: 'Security systems / Alarms',
    href: '/local-security-systems-alarms'
  },
  {
    display: 'Specialist Tradesperson',
    href: '/local-specialist-services'
  },
  {
    display: 'Stoneworker / Stonemason',
    href: '/local-stoneworkers-stonemasons'
  },
  {
    display: 'Swimming Pool Specialist',
    href: '/local-swimming-pool-specialists'
  },
  {
    display: 'Tiler',
    href: '/local-tilers'
  },
  {
    display: 'Traditional Craftspeople',
    href: '/local-traditional-craftspeople'
  },
  {
    display: 'Tree Surgeon',
    href: '/local-tree-surgeons'
  },
  {
    display: 'Window fitter / Conservatory installer',
    href: '/local-window-fitter-conservatory-installer'
  }
];
