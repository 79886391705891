import React from 'react';

import Layout from '../components/Layout';
import LandingPage from '../components/LandingPage';

export default function HomePage(props) {
  return (
    <Layout>
      <LandingPage {...props} />
    </Layout>
  );
}
