import React, { useEffect, useRef, useCallback, useReducer } from 'react';
import axios from 'axios';
import Downshift from 'downshift';
import FuzzySearch from 'fz-search';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import classNames from 'classnames';

import Modal from '@ratedpeople/modal';
import Button from '@ratedpeople/button';
import { isMobileView } from '@ratedpeople/utils';

import { dataLayerPush } from './utils';
import { popularSkillResults } from './data';

import style from './style.module.css';

const suggestionEngine = new FuzzySearch({
  output_limit: 20,
  output_map: 'item',
  token_field_min_length: 2
});

const genericReducer = (state, update) => {
  return { ...state, ...update };
};

function Typeahead({ restApiHostname, basePath, containerRef }) {
  const inputRef = useRef();
  const [state, dispatch] = useReducer(genericReducer, {
    value: '',
    inputItems: popularSkillResults,
    selectedSkill: null,
    error: '',
    isModalOpen: false
  });
  let itemCategory = '';

  useEffect(() => {
    const fetchTradesAndSkills = async () => {
      const items = [];
      const tradesAndSkillsEndpoint = `${restApiHostname}/trades-and-skills`;

      try {
        const { data } = await axios.get(tradesAndSkillsEndpoint);
        for (const trade in data) {
          data[trade].skills.forEach((skill) => {
            items.push({
              label: skill.name,
              value: skill.name,
              category: data[trade].name,
              tradeCode: trade,
              jobTypeId: skill.id,
              keywords: skill.synonyms ?? []
            });
          });
        }

        suggestionEngine.setOptions({
          source: items,
          keys: ['label', 'category', 'keywords']
        });
      } catch (err) {
        const errorMsg =
          get(err, 'response.data.error.message', false) ||
          `Could not get trades and skills from ${tradesAndSkillsEndpoint}`;
        console.log('ERROR', errorMsg);
        dispatch({ error: `Sorry, something's gone wrong on our end. Please refresh the page and try again.` });
      }
    };
    fetchTradesAndSkills();
  }, [restApiHostname]);

  useEffect(() => {
    if (state.isModalOpen) {
      inputRef.current.focus();
    }
  }, [state.isModalOpen]);

  const handleOnSubmit = async (ev) => {
    ev.preventDefault();
    const isValidForm =
      isEmpty(state.error) && !isEmpty(state.value) && get(state.selectedSkill, 'value', false) === state.value;

    if (!isEmpty(state.error)) {
      focusInput();
      return;
    } else if (!isValidForm) {
      let errorMsg = '';
      if (isEmpty(state.value)) {
        errorMsg = 'Type to search, or pick an option below';
      } else if (get(state.selectedSkill, 'value') !== state.value) {
        errorMsg = 'Please pick an option below';
      }

      focusInput();
      dispatch({ error: errorMsg });
      return;
    }

    window.location.assign(`${basePath}/${state.selectedSkill.tradeCode}/${state.selectedSkill.jobTypeId}`);
  };

  const focusInput = async () => {
    if (isMobileView() && !state.isModalOpen) {
      dispatch({ isModalOpen: true });
    } else {
      // eslint-disable-next-line no-unused-expressions
      inputRef?.current?.focus();
    }
  };

  const handleOnKeyUp = useCallback(
    debounce((ev) => {
      if (ev.target.value.length < 2 || ev.key.length > 1 || /[a-zA-Z]/.test(ev.key) === false) {
        return;
      }

      /* @todo: Do this with gtm when this is on the homepage **/
      dataLayerPush('Typeahead keyup', ev.target.value);
    }, 2000),
    []
  );

  const Input = (
    <Downshift
      itemToString={(item) => (item ? item.value : '')}
      onSelect={(selectedItem) => {
        const selectedItemValue = get(selectedItem, 'value', '');
        dispatch({
          value: selectedItemValue,
          selectedSkill: selectedItem,
          error: ''
        });

        /* @todo: Do this with gtm when this is on the homepage **/
        if (!isEmpty(selectedItemValue)) {
          const dataLayerAction =
            'Typeahead skill selection' + (selectedItem.category === 'Popular jobs' ? ' - popular jobs' : '');
          dataLayerPush(dataLayerAction, selectedItemValue);
        }
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectHighlightedItem,
        clearSelection,
        clearItems,
        openMenu
      }) => (
        <div className={style.typeahead}>
          <input
            {...getInputProps({
              value: state.value,
              onChange: (ev) => {
                const targetValue = ev.target.value;
                dispatch({
                  error: '',
                  value: targetValue
                });

                if (targetValue === '') {
                  clearSelection();
                  clearItems();
                  dispatch({ inputItems: popularSkillResults });
                  return;
                }

                let result = suggestionEngine.search(targetValue);
                if (targetValue.length > 1 && result.length === 0) {
                  dispatch({ error: 'No results found. Please try another search.' });
                  result = popularSkillResults;
                }
                dispatch({ inputItems: result });
              },
              onFocus: () => {
                openMenu();
                /* @todo: Do this with gtm when this is on the homepage **/
                dataLayerPush('Typeahead focus', '');
                if (containerRef.current) {
                  containerRef.current.dataset.typeahead = 'active';
                }
              },
              onBlur: () => {
                if (containerRef.current) {
                  containerRef.current.dataset.typeahead = 'inactive';
                }
              },
              onKeyDown: (ev) => {
                if (ev.key === 'Tab' && isOpen) {
                  /* Prevent Downshift's default 'Tab' behavior. **/
                  ev.nativeEvent.preventDownshiftDefault = true;
                  selectHighlightedItem();
                }
              },
              onKeyUp: (ev) => {
                ev.persist();
                handleOnKeyUp(ev);
              },
              ref: inputRef
            })}
            className={classNames(style.input, { [style.inputMenuOpen]: isOpen })}
            placeholder="What service are you looking for?"
          />
          <ul
            {...getMenuProps()}
            className={classNames(
              style.menu,
              { [style.menuOpen]: Boolean(isOpen && state.inputItems.length) },
              { [style.menuInactive]: !Boolean(isOpen && state.inputItems.length) }
            )}
          >
            {isOpen
              ? state.inputItems.reduce((acc, item, index, src) => {
                  if (index === 0 && state.error.length) {
                    acc.push(
                      <li key={`${state.error}`} className={classNames(style.menuItem, style.menuItemError)}>
                        {state.error}
                      </li>
                    );
                  }

                  if (itemCategory !== item.category) {
                    acc.push(
                      <li
                        key={`${item.category}-${index}`}
                        className={classNames(style.menuItem, style.menuItemCategory)}
                      >
                        {item.category}
                      </li>
                    );
                    itemCategory = item.category;
                  }

                  acc.push(
                    <li
                      {...getItemProps({
                        key: `${item.category}${item.label}`,
                        index,
                        item,
                        style: {
                          backgroundColor: highlightedIndex === index ? '#636065' : 'white',
                          color: highlightedIndex === index ? 'white' : '#211c23'
                        }
                      })}
                      className={classNames(style.menuItem, style.menuItemResult)}
                    >
                      {item.value}
                    </li>
                  );

                  if (index + 1 === src.length) {
                    itemCategory = '';
                  }
                  return acc;
                }, [])
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  );

  const mobileElements = (
    <>
      <Modal
        modalType="contentOnly"
        isOpen={state.isModalOpen}
        onClose={() => {
          dispatch({ isModalOpen: false });
        }}
        content={
          <form onSubmit={handleOnSubmit} className={classNames(style.form, style.mobile)}>
            <h2 className={style.modalHeading}>Create your job</h2>
            {Input}
            <Button theme="primary" size="giant" type="submit" onSubmit={handleOnSubmit}>
              Next step →
            </Button>
          </form>
        }
      />
      <input
        type="text"
        className={style.input}
        placeholder="What service are you looking for?"
        onFocus={async () => {
          focusInput();
        }}
        value={state.value}
        readOnly
      />
    </>
  );

  return (
    <form onSubmit={handleOnSubmit} className={style.form}>
      {isMobileView() ? mobileElements : Input}
      <Button theme="primary" className={style.button} type="submit">
        Next step →
      </Button>
    </form>
  );
}

Typeahead.propTypes = {
  restApiHostname: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  containerRef: PropTypes.object
};

Typeahead.defaultProps = {
  containerRef: {}
};

export default Typeahead;
