import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hasWebPSupport } from '@ratedpeople/utils';

import Typeahead from '../Typeahead';
import ContentTexts from './ContentTexts';

import style from './style.module.scss';

const propTypes = {
  backgroundImages: PropTypes.arrayOf(
    PropTypes.shape({
      sizeImage: PropTypes.string,
      source: PropTypes.string,
      sourceFallback: PropTypes.string
    })
  ),
  contentTexts: PropTypes.shape({
    headingSize: PropTypes.number,
    texts: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        title: PropTypes.string,
        description: PropTypes.string
      })
    )
  }),
  theme: PropTypes.oneOf(['default', 'inverted', 'full-width', 'hero', 'none']),
  inlineStyleJobFormArea: PropTypes.object,
  className: PropTypes.string,
  flexDirection: PropTypes.oneOf(['row', 'column']),
  flexJustify: PropTypes.oneOf(['start', 'end'])
};

const DefaultLayout = (props) => {
  const heroRef = useRef();

  return (
    <div className={style.hero}>
      <div
        className={classNames(
          style[`theme--${props.theme}`],
          style[`input-theme--${props.inputTheme}`],
          props.className
        )}
      >
        {Boolean(props.backgroundImages.length) &&
          props.backgroundImages.map((item) => {
            const { sizeImage, source, sourceFallback } = item;

            return (
              <div
                key={sizeImage}
                className={classNames(style[`display-${sizeImage}`], style['banner-image'])}
                style={{ backgroundImage: `url(${hasWebPSupport() ? source : sourceFallback})` }}
              />
            );
          })}
        <div ref={heroRef} data-typeahead="inactive"></div>
        <div
          style={props.inlineStyleJobFormArea}
          className={classNames({ [`${style['themed-jobform-area']}`]: props.theme === 'hero' })}
        >
          {props.contentTexts.texts && props.contentTexts.texts.length ? (
            <ContentTexts theme={props.theme} contentTexts={props.contentTexts} />
          ) : (
            <p className={style['title']}>Post your job for free, get up to 3 quotes</p>
          )}

          <div
            className={classNames(style['jobform-entry_inputs'], style[`jobform-entry_inputs--${props.flexDirection}`])}
          >
            <Typeahead
              restApiHostname={process.env.GATSBY_REST_API_HOSTNAME}
              basePath="/post-a-job"
              containerRef={heroRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

DefaultLayout.propTypes = propTypes;

export default DefaultLayout;
