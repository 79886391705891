import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from './ContentTexts.module.scss';

const propTypes = {
  theme: PropTypes.oneOf(['default', 'inverted', 'full-width', 'hero', 'none']),
  contentTexts: PropTypes.shape({
    headingSize: PropTypes.oneOf([1,2,3,4,5,6]),
    texts: PropTypes.arrayOf(PropTypes.shape({
      size: PropTypes.oneOf(['small', 'medium', 'large']),
      title: PropTypes.string,
      description: PropTypes.string
    }))
  })
};

const ContentTexts = ({ contentTexts, theme }) => {
  const { headingSize = 1, texts } = contentTexts;
  const HeadingEl = `h${headingSize}`;

  return texts.map((item) => {
    const {
      size,
      color,
      title,
      description
    } = item;

    return (
      size &&
      <div key={size}>
        { title &&
          <HeadingEl className={classNames(style[`title-${size}`], style[`title--${theme}`], style[`text--${color}`])}>
            {title}
          </HeadingEl>
        }
        { description &&
          <p className={style[`description-${size}`]}>
            {description}
          </p>
        }
      </div>
    );
  })
}

ContentTexts.propTypes = propTypes;

export default ContentTexts;
