/* @todo: Do this with gtm when this is on the homepage **/
export function dataLayerPush(action, label) {
  window.dataLayer = window.dataLayer || [];
  const config = {
    event: 'gaEvent',
    gaCategory: 'VWO',
    gaAction: 'ATT-212 ' + action,
    gaLabel: label
  };

  window.dataLayer.push(config);
}
