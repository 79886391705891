import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import style from './style.module.scss';

const propTypes = {
  detail: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
    className: PropTypes.string
  })
};

const SimpleCard = (props) => {
  const {
    title,
    description,
    buttonText,
    buttonLink,
    imageSrc,
    direction
  } = props.detail;

  const orientationStyle = `${style[`simple-card`]} ${
    style[`simple-card_${direction}`]
  }`;

  return (
    <div className={orientationStyle}>
      <LazyLoad offset={100} height={0}>
        <div
          style={{
            backgroundImage: `url(${imageSrc})`
          }}
          className={`${style[`img-block`]}`}
        />
      </LazyLoad>
      <div className={style.content}>
        <h2 className={style.title}>{title}</h2>
        <p className={style.description}>{description}</p>
        <p>
          <a className={style.link} href={buttonLink}>
            {buttonText}
          </a>
        </p>
      </div>
    </div>
  );
};

SimpleCard.propTypes = propTypes;

export default SimpleCard;
