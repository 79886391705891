import CardBlog from "../images/CardBlog.jpg";
import ShieldpayLpBanner from "../images/ShieldpayLpBanner.jpg";
import CardAskAnExpert from "../images/CardAskAnExpert.jpg";
import CardBudgets from "../images/CardBudgets.jpg";

export default [
  {
    title: "Introducing a new way to pay: protected payments",
    description:
      "Secure your money in a vault and release it when you’re ready. Protect yourself from fraud and get peace of mind.",
    buttonText: "Find out more",
    buttonLink: "https://www.shieldpay.com/rated-people/homeowners",
    imageSrc: ShieldpayLpBanner,
    direction: "horizontal",
    className: "example2"
  },
  {
    title: "Cost Guides",
    description:
      "Knowing how much a job should cost is a vital starting point for any job.",
    buttonText: "Find typical prices",
    buttonLink: "/cost-guides/",
    imageSrc: CardBudgets,
    direction: "vertical",
    className: "example4"
  },
  {
    title: "Ask an Expert",
    description:
      "Our community of tradespeople are here to help answer your questions.",
    buttonText: "Ask a question",
    buttonLink: "/diy-advice/",
    imageSrc: CardAskAnExpert,
    direction: "vertical",
    className: "example3"
  },
  {
    title: "Inspiration centre",
    description:
      "Your dream home starts with a great idea and we have plenty of them.",
    buttonText: "Get inspired",
    buttonLink: "/blog/inspiration/",
    imageSrc: CardBlog,
    direction: "vertical",
    className: "example3"
  }
];
